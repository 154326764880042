export const GET_LIFEREVIEW_START = 'GET_LIFEREVIEW_START'
export const GET_LIFEREVIEW_SUCCESS = 'GET_LIFEREVIEW_SUCCESS'
export const GET_LIFEREVIEW_FAILED = 'GET_LIFEREVIEW_FAILED'
export const GET_MORE_LIFEREVIEW_START = 'GET_MORE_LIFEREVIEW_START'
export const GET_MORE_LIFEREVIEW_SUCCESS = 'GET_MORE_LIFEREVIEW_SUCCESS'
export const GET_MORE_LIFEREVIEW_FAILED = 'GET_MORE_LIFEREVIEW_FAILED'

export const CREATE_ARTICLE_START = 'CREATE_ARTICLE_START'
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS'
export const CREATE_ARTICLE_FAILED = 'CREATE_ARTICLE_FAILED'
export const CREATE_ARTICLE_RESET = 'CREATE_ARTICLE_RESET'

export const GET_ARTICLE_START = 'GET_ARTICLE_START'
export const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS'
export const GET_ARTICLE_FAILED = 'GET_ARTICLE_FAILED'

export const UPDATE_ARTICLE_START = 'UPDATE_ARTICLE_START'
export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS'
export const UPDATE_ARTICLE_FAILED = 'UPDATE_ARTICLE_FAILED'
export const UPDATE_ARTICLE_RESET = 'UPDATE_ARTICLE_RESET'

export const DELETE_ARTICLE_START = 'DELETE_ARTICLE_START'
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS'
export const DELETE_ARTICLE_FAILED = 'DELETE_ARTICLE_FAILED'

export const GET_MESSAGES_START = 'GET_MESSAGES_START'
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export const GET_MESSAGES_FAILED = 'GET_MESSAGES_FAILED'
export const GET_MORE_MESSAGES_START = 'GET_MORE_MESSAGES_START'
export const GET_MORE_MESSAGES_SUCCESS = 'GET_MORE_MESSAGES_SUCCESS'
export const GET_MORE_MESSAGES_FAILED = 'GET_MORE_MESSAGES_FAILED'
export const GET_SINGLE_MESSAGE_START = 'GET_SINGLE_MESSAGE_START'
export const GET_SINGLE_MESSAGE_SUCCESS = 'GET_SINGLE_MESSAGE_SUCCESS'
export const GET_SINGLE_MESSAGE_FAILED = 'GET_SINGLE_MESSAGE_FAILED'

export const CREATE_MESSAGE_START = 'CREATE_MESSAGE_START'
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS'
export const CREATE_MESSAGE_FAILED = 'CREATE_MESSAGE_FAILED'
export const CREATE_MESSAGE_RESET = 'CREATE_MESSAGE_RESET'

export const GET_MESSAGE_START = 'GET_MESSAGE_START'
export const GET_MESSAGE_SUCCESS = 'GET_MESSAGE_SUCCESS'
export const GET_MESSAGE_FAILED = 'GET_MESSAGE_FAILED'

export const UPDATE_MESSAGE_START = 'UPDATE_MESSAGE_START'
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS'
export const UPDATE_MESSAGE_FAILED = 'UPDATE_MESSAGE_FAILED'

export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'

export const LOGOUT_START = 'LOGOUT_START'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILED = 'LOGOUT_FAILED'

